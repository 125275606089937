import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Slide } from "react-awesome-reveal";
import CV from '../../assets/cv.pdf'
import Me from '../../assets/me.jpg'

const ProfComponent = () => {
  return (
    <Container id="home">
      <Slide direction="left">
        <Texts>
          <h2>
            Hello <span className="green">I'm</span>
          </h2>
          <h1 className="green">Udara Dilaksha</h1>
          <h3>IT Undergraduate</h3>
          <p>
          I am a 22  years old Sri lankan who is enthusiastic, hardworking and ambitious to achieve my goals. 
          My objective is to explore new opportunities, gain more knowledge and new experienced in the 
          IT industry as a Web developer.
          </p>
          <p>
          I Love doing activities such as Web Developing, Outdoor games, Playing video games, Music, 
          Mostly Travelling. 
          </p>
          <a href={CV} download="Udara_Dilaksha_CV.pdf"><button>Download CV</button></a>
          <a href="#footer"><button>Let's Talk</button></a>
          <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a href="https://www.Instagram.com/hey_im_udara/">
                  <AiOutlineInstagram />
                </a>
              </span>
              <span>
                <a href="https://github.com/nudara2000">
                  <AiOutlineGithub />
                </a>
              </span>
              <span>
                <a href="https://www.linkedin.com/in/udara-dilaksha/">
                  <FaLinkedinIn />
                </a>
              </span>
            </div>
          </Social>
        </Texts>
      </Slide>
      <Slide direction="right">
        <Profile>
          <img src={Me} alt="me"/>
        </Profile>
      </Slide>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
    margin-bottom: 2rem; 
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 4rem;
    margin-right: 2rem;
    border-radius: 0.3rem;
    cursor: pointer;
    background-color:#4db5ff ;
    border: none;
    color: #fff;
    font-weight: 500;
    filter: drop-shadow(0px 1px 2px #4db5ff);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
 
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    margin-top: 2rem; 
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #4db5ff;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 20rem;
    filter: drop-shadow(0px 5px 5px #4db5ff);
    transition: transform 400ms ease-in-out;
    border-radius: 50%;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;